<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps({
    href: String,
    active: String,
    classes: String,
});

const cls = computed(() =>{
    return props.active
        ? "inline-flex px-1 py-1 my-1 items-center focus:outline-none transition"
        : "inline-flex px-1 py-1 my-1 items-center focus:outline-none transition";
});
</script>

<template>
  <a :title="href" target="_blank" :href="href" :class="classes+' '+cls">
    <slot />
  </a>
</template>
