import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import VueSmoothScroll from 'vue3-smooth-scroll'
import {translations} from './translations';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'sahabsoft.net';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        const myApp = createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueSmoothScroll)
            .mixin(translations)
            /*.component('any',s)*/
            myApp.mount(el);
            return myApp;
    },
});

InertiaProgress.init({ color: '#8C492E' });
