<script setup>
</script>

<template>
<div class="w-full min-h-screen grid grid-cols-2">
    <div class="col-span-2 md:col-span-1 order-1 md:order-2 p-10">
        <div class="py-2">
            <slot name="title"></slot>
            <slot name="desc"></slot>
        </div>
        <div class="py-2">
            <slot name="form"></slot>
        </div>
    </div>
    <div class="col-span-2 md:col-span-1 order-2 md:order-1 w-full h-full">
        <div class="bg-black/50 p-10 rounded-lg w-full h-full md:rounded-br-full">
            <slot name="map"></slot>
        </div>
    </div>
</div>
</template>

<style scoped>
</style>
