<script setup>
import { onMounted, onUnmounted, ref, reactive } from 'vue';
import { Link, useForm } from '@inertiajs/inertia-vue3';
import StepCard from '@/components/Cards/StepCard.vue';
import GuestLayout from "@/Layouts/GuestLayout.vue";
import GuestNav from "@/Components/Navs/GuestNav.vue";
import Contact from '@/components/Cards/Contact.vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import VedioCard from '@/components/Cards/VedioCard.vue';

const props = defineProps({
    canLogin: Boolean,
    isSent: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
    stepCards: Object,
    lastProjects: Object,
    Lastworks: Object,
    services: Object,
});

const maxCount = ref(props.stepCards?.length || 0);
const cardCount = ref(1);
const windowTop = ref(0);
const fixedClass = ref("bg-transparent transition duration-400");
const linksClass = ref("text-celadon-10 hover:text-celadon-40 transition duration-400 drop-shadow");
const isSent = ref(0);

const form = useForm({
    name: '',
    email: '',
    message: '',
    mail_id: '',
});

const set_count = (num) => {
    TempCount = num;
}

const send_mail = () => {
    form.mail_id = Date.now();
    form.post(route('sahabsoft.mail'), {
        errorBag: 'send_mail',
        preserveScroll: true,
        onSuccess: () => {
            form.reset();
            isSent.value = 1;
        },
        onError: () => {
            isSent.value = 2;
        },
    });
};

const getInstance = (art) => {
    //console.log(art);
}

const option = reactive({
    url: "/videos/zakah.mp4",
    title: 'sahabsoft - For Digital solutions',
    poster: '/videos/zakah.png',
    theme: '#ffffff',
    volume: 0.5,
    muted: false,
    lock: true,
    isLive: true,
    autoplay: false,
    pip: true,
    autoSize: true,
    autoMini: false,
    screenshot: true,
    setting: true,
    loop: true,
    flip: true,
    autoOrientation: true,
    fastForward: true,
    playbackRate: true,
    aspectRatio: true,
    fullscreen: true,
    fullscreenWeb: true,
    subtitleOffset: true,
    miniProgressBar: true,
    mutex: true,
    backdrop: true,
    playsInline: true,
    autoPlayback: true,
    airplay: true,
    whitelist: ['*'],
});

const option2 = reactive({
    url: "/videos/project.mp4",
    title: 'sahabsoft - For Digital solutions',
    poster: '/videos/project.png',
    theme: '#ffffff',
    volume: 0.5,
    muted: false,
    lock: true,
    isLive: true,
    autoplay: false,
    pip: true,
    autoSize: true,
    autoMini: false,
    screenshot: true,
    setting: true,
    loop: true,
    flip: true,
    autoOrientation: true,
    fastForward: true,
    playbackRate: true,
    aspectRatio: true,
    fullscreen: true,
    fullscreenWeb: true,
    subtitleOffset: true,
    miniProgressBar: true,
    mutex: true,
    backdrop: true,
    playsInline: true,
    autoPlayback: true,
    airplay: true,
    whitelist: ['*'],
});

const breakpoints = reactive({
    // 700px and up
    700: {
        itemsToShow: 2,
        snapAlign: 'center',
    },
    // 1024 and up
    1024: {
        itemsToShow: 3,
        snapAlign: 'center',
    },
});

const breakpoints1 = reactive({
    // 700px and up
    1: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
});

const settings = reactive({
    itemsToShow: 2,
    snapAlign: 'center',
});

const settings1 = reactive({
    itemsToShow: 1,
    snapAlign: 'center',
});

const StepCardMove = (val) => {
    if (val >= 0) {
        cardCount.value = (cardCount.value + val) >= maxCount.value ? maxCount.value : (cardCount.value + val);
    }
    else {
        val = val * -1;
        cardCount.value = (cardCount.value - val) <= 1 ? 1 : (cardCount.value - val);
    }
};

const onScroll = (e) => {
    windowTop.value = e.target.documentElement.scrollTop;
    /*console.log({ top: windowTop.value });*/

    if (document.querySelector("#nav").getBoundingClientRect().top < -200) {
        fixedClass.value = "bg-white hover:backdrop-blur-2xl transition duration-400";
        linksClass.value = "text-celadon-400 hover:text-celadon-800 transition duration-400 drop-shadow";
    }
    else {
        fixedClass.value = "bg-transparent transition duration-400";
        linksClass.value = "text-celadon-10 hover:text-celadon-40 transition duration-400 drop-shadow";
    }
}

onMounted(() => {
    document.addEventListener("scroll", onScroll);
});

onUnmounted(() => {
    document.removeEventListener("scroll", onScroll);
});
</script>

<template>
    <GuestLayout theme="sahabsoft" :title="__('sahabsoft')">
        <!-- Page Header -->
        <div class="w-full" id="nav">
            <GuestNav dir="ltr" :linksClass="linksClass"
                :class="fixedClass + ' ' + 'fixed top-0 left-0 z-50 w-full py-2 shadow-sm'" theme="sahabsoft" />
        </div>
        <!-- Hero Section -->
        <section class="w-full h-screen overflow-hidden relative bg-[url(/images/bg/hero.jpg)] bg-cover z-10">
            <div class="absolute top-0 left-0 w-full h-screen">
                <section class="z-0 w-full h-screen px-40 heroLogo">
                    <div class="z-0 sahabsoft">
                        <svg version="1.1" id="sahab" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 99.21 44.488"
                            enable-background="new 0 0 99.21 44.488" xml:space="preserve">
                            <path
                                d="M10.396,42.367c0.05,0.016,0.102,0.029,0.15,0.045c-0.936-1.068-1.54-2.188-1.591-3.576C8.933,38.82,9.361,42.004,10.396,42.367" />
                            <path
                                d="M19.787,35.615c-0.359-0.588-0.917-1.092-1.66-1.502c-0.811-0.447-1.879-0.832-3.169-1.146
                            c-0.853-0.203-1.493-0.387-1.957-0.561c-0.541-0.203-0.95-0.441-1.25-0.73c-0.385-0.369-0.588-0.865-0.588-1.441
                            c0-0.736,0.344-1.357,0.995-1.795c0.551-0.373,1.277-0.563,2.157-0.563c0.848,0,1.679,0.16,2.468,0.473
                            c0.708,0.283,1.424,0.684,2.12,1.186l1.298-2.066c-1.725-1.359-3.643-2.021-5.86-2.021c-1.248,0-2.363,0.209-3.315,0.627
                            c-0.921,0.404-1.616,0.969-2.126,1.727c-0.504,0.75-0.75,1.633-0.75,2.701c0,0.91,0.181,1.67,0.536,2.262
                            c0.363,0.6,0.885,1.08,1.6,1.467c0.776,0.422,1.792,0.785,3.017,1.084c0.923,0.221,1.612,0.418,2.108,0.598
                            c0.586,0.215,1.019,0.467,1.325,0.771c0.375,0.377,0.574,0.865,0.574,1.414c0,0.762-0.39,1.418-1.096,1.846
                            c-0.595,0.363-1.38,0.539-2.4,0.539c-0.894,0-1.797-0.166-2.684-0.49c-0.795-0.293-1.527-0.684-2.172-1.156
                            c0.05,1.387,0.656,2.508,1.592,3.576c0.991,0.33,2.086,0.502,3.264,0.502c1.34,0,2.529-0.219,3.536-0.646
                            c0.971-0.412,1.694-0.984,2.211-1.748c0.514-0.76,0.763-1.646,0.763-2.713C20.321,36.934,20.14,36.197,19.787,35.615" />
                            <path
                                d="M38.189,42.57c-0.171,0-0.251-0.023-0.273-0.029c0-0.008-0.073-0.094-0.179-0.318l-5.481-11.27
                            l-5.417,11.266c-0.109,0.23-0.185,0.311-0.199,0.324h-0.001c-0.009,0-0.094,0.027-0.282,0.027h-2.686l8.233-16.389
                            c0.206-0.426,0.343-0.65,0.426-0.766c0.094,0.133,0.237,0.365,0.45,0.775l8.28,16.379H38.189z" />
                            <path d="M56.484,42.57l-0.002-6.061c0-0.473-0.189-0.762-0.348-0.922c-0.236-0.234-0.555-0.352-0.953-0.352h-6.115
                            c-0.423,0-0.753,0.135-0.979,0.398c-0.194,0.225-0.292,0.52-0.292,0.875v6.061h-2.853L44.94,25.791h2.855v5.689
                            c0,0.357,0.099,0.652,0.292,0.875c0.224,0.262,0.555,0.398,0.979,0.398h6.115c0.4,0,0.719-0.119,0.951-0.352
                            c0.158-0.158,0.35-0.447,0.35-0.922v-5.689h2.852l0.002,16.779H56.484z" />
                            <path
                                d="M77.734,42.57c-0.172,0-0.252-0.023-0.273-0.029c0-0.008-0.072-0.094-0.18-0.318l-5.48-11.27l-5.416,11.266
                            c-0.111,0.23-0.186,0.311-0.199,0.324h-0.002c-0.01,0-0.092,0.027-0.281,0.027h-2.686l8.232-16.389
                            c0.205-0.424,0.344-0.65,0.426-0.766c0.094,0.133,0.238,0.363,0.449,0.775l8.279,16.379H77.734z" />
                            <path d="M84.488,42.57l-0.002-16.779h6.748c2.121,0,3.725,0.357,4.762,1.066c0.943,0.643,1.404,1.697,1.404,3.221
                            c0,0.732-0.158,1.328-0.48,1.824c-0.328,0.5-0.762,0.877-1.322,1.143l-2.057,0.977l2.18,0.652c0.703,0.211,1.242,0.584,1.652,1.137
                            c0.408,0.557,0.609,1.201,0.609,1.971c0,1.666-0.521,2.838-1.596,3.58c-1.158,0.801-2.865,1.209-5.072,1.209H84.488z M88.613,35.236
                            c-0.148,0-0.6,0-0.938,0.336c-0.334,0.336-0.334,0.787-0.334,0.938v2.516c0,0.354,0.098,0.648,0.291,0.875
                            c0.225,0.262,0.555,0.395,0.98,0.395h2.701c1.143,0,1.982-0.17,2.568-0.521c0.494-0.297,1.086-0.887,1.086-2.02
                            c0-0.895-0.404-1.613-1.137-2.021c-0.6-0.334-1.422-0.496-2.518-0.496H88.613z M88.613,28.064c-0.424,0-0.754,0.133-0.98,0.396
                            c-0.193,0.227-0.291,0.52-0.291,0.875v2.355c0,0.357,0.098,0.652,0.291,0.875c0.225,0.264,0.555,0.398,0.98,0.398h2.355
                            c1.094,0,1.891-0.162,2.441-0.498c0.469-0.285,1.029-0.857,1.029-1.965c0-0.871-0.369-1.566-1.041-1.961
                            c-0.549-0.32-1.342-0.477-2.43-0.477H88.613z" />
                            <path
                                d="M60.23,17.279c-0.883-0.347-1.813-0.575-2.764-0.677l-0.811-0.086l-0.248-0.777
                            c-0.887-2.786-2.76-6.767-6.649-9.912c-1.307-1.058-2.822-1.983-4.382-2.676c-2.6-1.153-5.455-1.738-8.486-1.738
                            c-3.47,0-6.011,0.788-6.29,0.878c-8.848,2.854-12.323,10.926-12.93,12.516l-0.334,0.875l-0.934-0.075
                            c-0.048-0.004-0.308-0.023-0.727-0.023c-2.279,0-7.972,0.525-11.613,5.384c-3.297,4.4-2.619,9.385-2.449,10.338
                            c0.037,0.211,0.884,4.744,4.733,8.008c-1.045-2.074-1.833-4.73-1.58-7.844c0.274-3.361,1.608-6.426,3.756-8.627
                            c3.247-3.33,8.262-4.57,10.66-4.57h0.007c1.865-3.624,3.993-6.46,6.329-8.438c2.941-2.491,5.93-3.368,7.055-3.697
                            c0.746-0.219,3.017-0.885,5.991-0.885c2.52,0,4.94,0.475,7.194,1.415c6.664,2.776,9.582,8.579,10.627,11.316
                            c0.082-0.001,0.166-0.002,0.248-0.002c2.744,0,5.176,0.573,7.248,1.706C62.479,18.298,61.021,17.591,60.23,17.279" />
                            <path
                                d="M24.138,23.089c-0.425,0-0.819-0.063-1.172-0.187c-0.338-0.119-0.648-0.285-0.919-0.491l0.386-0.599
                            c0.238,0.166,0.481,0.291,0.744,0.386c0.318,0.118,0.642,0.177,0.962,0.177c0.371,0,0.656-0.065,0.877-0.198
                            c0.268-0.164,0.416-0.414,0.416-0.705c0-0.211-0.077-0.399-0.222-0.543c-0.113-0.114-0.273-0.207-0.488-0.287
                            c-0.176-0.063-0.419-0.133-0.746-0.211c-0.422-0.103-0.772-0.229-1.038-0.372c-0.24-0.13-0.414-0.29-0.534-0.489
                            c-0.118-0.196-0.178-0.451-0.178-0.756c0-0.359,0.082-0.656,0.252-0.907c0.17-0.254,0.404-0.444,0.714-0.58
                            c0.324-0.142,0.706-0.214,1.131-0.214c0.745,0,1.39,0.217,1.971,0.663l-0.391,0.602c-0.238-0.164-0.47-0.29-0.7-0.381
                            c-0.284-0.113-0.583-0.171-0.888-0.171c-0.323,0-0.589,0.071-0.793,0.209c-0.244,0.164-0.379,0.407-0.379,0.686
                            c0,0.22,0.079,0.412,0.227,0.553c0.112,0.108,0.263,0.196,0.461,0.271c0.164,0.061,0.391,0.126,0.693,0.198
                            c0.446,0.109,0.814,0.242,1.093,0.395c0.248,0.137,0.436,0.306,0.555,0.501c0.118,0.192,0.177,0.438,0.177,0.731
                            c0,0.359-0.083,0.657-0.255,0.912c-0.173,0.256-0.417,0.449-0.744,0.587C25.005,23.015,24.597,23.089,24.138,23.089" />
                            <path d="M33.616,23.09c-0.589,0-1.125-0.129-1.591-0.382c-0.467-0.254-0.823-0.596-1.086-1.049
                            c-0.261-0.449-0.393-0.967-0.393-1.539c0-0.578,0.133-1.1,0.397-1.552c0.268-0.458,0.626-0.807,1.095-1.065
                            c0.467-0.259,1.003-0.389,1.597-0.389c0.6,0,1.144,0.13,1.613,0.385c0.465,0.253,0.833,0.608,1.093,1.055
                            c0.261,0.45,0.394,0.97,0.394,1.548c0,0.578-0.134,1.098-0.398,1.547c-0.266,0.454-0.626,0.798-1.101,1.055
                            C34.761,22.959,34.217,23.09,33.616,23.09 M33.634,17.846c-0.404,0-0.777,0.096-1.108,0.286c-0.335,0.192-0.6,0.467-0.788,0.815
                            c-0.183,0.341-0.276,0.732-0.276,1.164c0,0.433,0.093,0.824,0.277,1.161c0.188,0.345,0.451,0.616,0.784,0.806
                            c0.328,0.185,0.699,0.279,1.103,0.279c0.404,0,0.777-0.095,1.108-0.285c0.331-0.189,0.599-0.46,0.793-0.804
                            c0.191-0.34,0.289-0.729,0.289-1.157c0-0.439-0.095-0.835-0.283-1.173c-0.19-0.343-0.456-0.614-0.79-0.806
                            C34.411,17.942,34.039,17.846,33.634,17.846" />
                            <path
                                d="M41.3,22.969v-5.735h3.687v0.732l-2.311,0c-0.211,0-0.332,0.089-0.396,0.162
                            c-0.079,0.094-0.119,0.212-0.119,0.352v0.955c0,0.158,0.046,0.285,0.138,0.376c0.091,0.091,0.218,0.138,0.376,0.138h1.997v0.731
                            l-1.997,0c-0.212,0-0.333,0.09-0.396,0.165c-0.078,0.089-0.118,0.208-0.118,0.349v1.774H41.3z" />
                            <path
                                d="M50.778,22.969V18.48c0-0.189-0.077-0.306-0.141-0.371c-0.068-0.068-0.187-0.144-0.374-0.144h-1.328v-0.732
                            h4.547l0.002,0.732h-1.332c-0.188,0-0.307,0.078-0.371,0.142c-0.064,0.064-0.143,0.181-0.143,0.372v4.488H50.778z" />
                        </svg>
                        <h2>For Digital Solutions</h2>
                    </div>
                </section>
            </div>

            <Suspense>
                <template #default>
                    <Carousel class="w-full h-full" :autoplay="4000" :wrap-around="true">
                        <Slide class="bg-no-repeat bg-cover">
                            <div class="flex flex-col justify-end w-full h-screen ltr:left-0 rtl:right-0">
                                <div
                                    class="px-12 py-5 mx-10 mt-40 rounded-lg md:w-4/6 h-fit bg-celadon-100/40 md:rounded-none md:rounded-tr-full md:py-14 md:mx-0">
                                    <h1 class="pb-5 font-mono text-lg font-extrabold drop-shadow-lg text-crayola-40">{{
                                        __('sahabsoftSlide1')
                                        }}</h1>
                                    <p class="mt-2 text-sm font-bold font-lemonada drop-shadow-lg text-crayola-30">{{
                                        __('')
                                        }}</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide class="bg-no-repeat bg-cover">
                            <div class="flex flex-col justify-end w-full h-screen ltr:left-0 rtl:right-0">
                                <div
                                    class="px-12 py-5 mx-10 mt-40 rounded-lg md:w-4/6 h-fit bg-celadon-100/40 md:rounded-none md:rounded-tr-full md:py-14 md:mx-0">
                                    <h1 class="pb-5 font-mono text-lg font-extrabold drop-shadow-lg text-crayola-40">{{
                                        __('sahabsoftSlide2')
                                        }}</h1>
                                    <p class="mt-2 text-sm font-bold font-lemonada drop-shadow-lg text-crayola-30">{{
                                        __('')
                                        }}</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide class="bg-no-repeat bg-cover">
                            <div class="flex flex-col justify-end w-full h-screen ltr:left-0 rtl:right-0">
                                <div
                                    class="px-12 py-5 mx-10 mt-40 rounded-lg md:w-4/6 h-fit bg-celadon-100/40 md:rounded-none md:rounded-tr-full md:py-14 md:mx-0">
                                    <h1 class="pb-5 font-mono text-lg font-extrabold drop-shadow-lg text-crayola-40">{{
                                        __('sahabsoftSlide3')
                                        }}</h1>
                                    <p class="mt-2 text-sm font-bold font-lemonada drop-shadow-lg text-crayola-30">{{
                                        __('')
                                        }}</p>
                                </div>
                            </div>
                        </Slide>
                        <template #addons>
                            <!--<Navigation class="mx-8"/>-->
                            <Pagination class="absolute bottom-0 w-full" />
                        </template>
                    </Carousel>
                </template>
                <template #fallback>
                    <div>...</div>
                </template>
            </Suspense>
            <img src="images/logo/vision_logo.png"
                class="absolute z-0 w-96 h-96 -bottom-20 -right-20 opacity-10 animate-spin-slow">
        </section>
        <!-- vedio -->
        <section class="relative z-0 w-full min-h-screen p-5 bg-white md:p-20">
            <div class="relative z-10 bottom-20" id="vedio"></div>
            <div class="z-0 cloud_bg">
                <svg class="clouds cloud1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" x="0" y="0" width="512" height="512" viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512" xml:space="preserve">
                    <path id="cloud-icon"
                        d="M406.1 227.63c-8.23-103.65-144.71-137.8-200.49-49.05 -36.18-20.46-82.33 3.61-85.22 45.9C80.73 229.34 50 263.12 50 304.1c0 44.32 35.93 80.25 80.25 80.25h251.51c44.32 0 80.25-35.93 80.25-80.25C462 268.28 438.52 237.94 406.1 227.63z" />
                </svg>
                <svg class="clouds cloud2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" x="0" y="0" width="512" height="512" viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512" xml:space="preserve">
                    <path id="cloud-icon"
                        d="M406.1 227.63c-8.23-103.65-144.71-137.8-200.49-49.05 -36.18-20.46-82.33 3.61-85.22 45.9C80.73 229.34 50 263.12 50 304.1c0 44.32 35.93 80.25 80.25 80.25h251.51c44.32 0 80.25-35.93 80.25-80.25C462 268.28 438.52 237.94 406.1 227.63z" />
                </svg>
                <svg class="clouds cloud3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" x="0" y="0" width="512" height="512" viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512" xml:space="preserve">
                    <path id="cloud-icon"
                        d="M406.1 227.63c-8.23-103.65-144.71-137.8-200.49-49.05 -36.18-20.46-82.33 3.61-85.22 45.9C80.73 229.34 50 263.12 50 304.1c0 44.32 35.93 80.25 80.25 80.25h251.51c44.32 0 80.25-35.93 80.25-80.25C462 268.28 438.52 237.94 406.1 227.63z" />
                </svg>
            </div>
            <div class="z-10 grid mb-20 space-y-4 place-items-center">
                <h3 class="text-xl font-bold tracking-wide text-celadon-800 md:text-5xl font-work_sans">{{
                    __('sahabsoftStepByStep')
                    }}</h3>
                <p class="font-semibold leading-relaxed text-gray-600 font-work_sans">{{ __('sahabsoftStepByStepDesc')
                    }}</p>
            </div>
            <VedioCard @get-instance="getInstance" class="relative z-10 w-full mt-5 h-80 md:mt-20" :option="option" />
        </section>
        <!-- short desc -->
        <section class="px-5 pt-5 md:pt-10 lg:px-24 md:px-16 bg-yellow-50">
            <div class="grid mt-5 space-y-4 place-items-center">
                <h3 class="pb-2 mb-5 text-xl font-bold tracking-wide text-teal-800 md:text-5xl font-work_sans">{{
                    __('needToImprove')
                    }}</h3>
            </div>
            <Carousel :settings="settings1" :breakpoints="breakpoints1" class="w-full h-full" :autoplay="4000"
                :itemsToShow="3.95" :wrapAround="true">
                <Slide class="inline-flex max-w-6xl mx-auto h-fit" disabled="disabled">
                    <div class="w-full p-5 proj_item">
                        <video autoplay muted loop class="min-w-full min-h-full border-y-celadon-50/20">
                            <source src="/videos/ci_1.mov" type="video/mp4">
                        </video>
                    </div>
                </Slide>
                <Slide class="inline-flex max-w-6xl mx-auto h-fit" disabled="disabled">
                    <div class="w-full p-5 proj_item">
                        <video autoplay muted loop class="min-w-full min-h-full border-y-celadon-50/20">
                            <source src="/videos/ci_2.mov" type="video/mp4">
                        </video>
                    </div>
                </Slide>
                <Slide class="inline-flex max-w-6xl mx-auto h-fit" disabled="disabled">
                    <div class="w-full p-5 proj_item">
                        <video autoplay muted loop class="min-w-full min-h-full border-y-celadon-50/20">
                            <source src="/videos/ci_3.mov" type="video/mp4">
                        </video>
                    </div>
                </Slide>
            </Carousel>
        </section>
        <!-- About Us Section -->
        <section class="w-full">
            <div class="relative bottom-20" id="about"></div>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div class="order-2 col-span-1 px-10 md:p-20 md:order-1">
                    <h1 class="my-1 text-2xl font-extrabold">{{ __('aboutUs') }}</h1>
                    <p class="py-2 text-xl">{{ __('sahabsoftSlideDesc1') }}</p>
                    <p class="py-2 text-xl">{{ __('sahabsoftSlideDesc2') }}</p>
                    <h1 class="my-1 text-2xl font-extrabold">{{ __('ourGoal') }}</h1>
                    <p class="py-2 text-xl">{{ __('aboutUssahabsoftDesc2') }}</p>
                </div>
                <div class="justify-center order-1 hidden col-span-1 md:order-2 md:flex">
                    <Suspense>
                        <template #default>
                            <img class="mt-10 h-80 w-96" src="/images/bg/about.png" alt="about us">
                        </template>
                        <template #fallback>
                            <div>...</div>
                        </template>
                    </Suspense>
                </div>
            </div>
        </section>
        <!-- Last project Section -->
        <section class="w-full min-h-screen px-10 mt-10">
            <div class="relative bottom-20" id="lastWorks"></div>
            <div class="grid pb-10 space-y-4 place-items-center">
                <h3 class="text-2xl font-bold tracking-wide text-teal-800 md:text-5xl font-work_sans">{{ __('lastWorks')
                    }}</h3>
                <p class="font-semibold leading-relaxed text-gray-600 font-work_sans">
                    {{ __('lastWorksDesc') }}
                </p>
                <p class="font-semibold leading-relaxed text-gray-600 font-work_sans">{{ __('sahabsoftSlideDesc3') }}
                </p>
            </div>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div v-if="lastProjects?.data && lastProjects.data.length != 0" v-for="lastProject in lastProjects.data"
                    :key="lastProject.id" class="relative col-span-1 overflow-hidden cursor-pointer">
                    <Suspense>
                        <template #default>
                            <img class="z-0 w-full h-full"
                                :src="lastProject.img_url ? '/storage/'+lastProject.img_url : ''"
                                :alt="locale() == 'ar' ? lastProject.ar_title : lastProject.en_title">
                        </template>
                        <template #fallback>
                            <div>...</div>
                        </template>
                    </Suspense>
                    <Link :href="route('/')+'/project/'+lastProject.id"
                        class="absolute top-0 z-10 flex items-center justify-center w-full h-full text-xl font-extrabold text-white opacity-0 bg-gradient-to-t from-gray-600 to-celadon-50 hover:opacity-90">
                    {{ locale() == 'ar' ? lastProject.ar_title : lastProject.en_title }}</Link>
                </div>
                <p v-else class="py-10 text-5xl text-center opacity-20 col-span-full">{{ __('nothingYet') }}</p>
            </div>
            <div class="flex flex-row justify-center w-full p-2 text-center">
                <Link v-if="props.lastProjects?.next_page_url" :href="props.lastProjects.next_page_url"
                    class="px-2 py-1 m-1 text-xl font-extrabold text-white rounded-lg bg-gradient-to-t from-celadon-600 to-celadon-100 hover:opacity-90">
                Next</Link>
                <Link v-if="props.lastProjects?.prev_page_url" :href="props.lastProjects.prev_page_url"
                    class="px-2 py-1 m-1 text-xl font-extrabold text-white rounded-lg bg-gradient-to-t from-celadon-600 to-celadon-100 hover:opacity-90">
                Previous</Link>
            </div>
        </section>
        <!-- Last Works Section -->
        <section class="w-full min-h-screen px-10 mt-40">
            <div class="relative bottom-20" id="lastCameraWorks"></div>
            <div class="grid pb-10 space-y-4 place-items-center">
                <h3 class="text-2xl font-bold tracking-wide text-teal-800 md:text-5xl font-work_sans">{{
                    __('lastCameraWorks')
                    }}</h3>
                <p class="font-semibold leading-relaxed text-gray-600 font-work_sans">
                    {{ __('lastCameraWorksDescIntro') }}
                </p>
                <div class="flex flex-col items-center justify-center w-full py-5 text-center">
                        <a :href="__('cctvSecurityLife')" target="_blank" class="px-4 transition rounded-lg text-crayola-10 bg-celadon-700 hover:bg-celadon-300">
                            {{ __('payNow') }}
                            <svg version="1.1" class="inline w-16 h-16 px-2 text-xl font-extrabold stroke-crayola-10 fill-crayola-10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;"
                                xml:space="preserve">
                                <path d="M14.9,7.1c-1.8,1.3-3.5,2.7-5.1,4.2c-0.9,0.9-1.9,1.1-3,1.3c-1.3,0.2-2.5,0.1-3.8,0.2c-0.7,0.1-0.8-0.4-0.8-1.1 c0.1-1.9,1.7-3.9,3.5-4C8.8,7.4,11.9,7.3,14.9,7.1z M3.8,12.4c0.5,0,1.1-0.1,1.2-1.1c0-0.6-0.4-0.8-0.8-0.8c-0.6,0-1.2,0.4-1.3,1.3 C2.9,12.3,3.3,12.4,3.8,12.4z" />
                                <path d="M10.5,11.6c1.2,1.1,2.3,0.8,2.9-0.5c0.2-0.4,0.3-0.8,0.1-1.2c-0.3-0.6-0.1-0.8,0.3-1c0.3-0.1,0.6-0.6,0.9-0.2 C14.9,9.1,15,9.5,15,10c-0.1,1.4-1.1,2.9-2.2,3.3C11.6,13.7,11,13.3,10.5,11.6z" />
                                <path d="M15.4,8c0.6-0.3,1.2-0.5,1.7-0.4c0.6,0.1,0.8,0.5,0.4,1.1c-0.4,0.7-0.9,1.3-1.7,1.6C16,9.5,15.8,8.7,15.4,8z" />
                                <path d="M6.4,13.6c0.8-0.2,1.7-0.3,2.4-0.7c0.5-0.3,0.9-0.5,1.2,0.4c0.1,0.2,0.2,0.4,0.5,0.7C9.1,13.9,7.7,14.3,6.4,13.6z" />
                                <path fill="none" d="M14.5,16l-5.4,1.5c-3.4,0.9-7-1.1-8-4.6L0.9,12C0,8.5,2,5,5.5,4l5.4-1.5c3.4-0.9,7,1.1,8,4.6L19.1,8 C20,11.5,18,15,14.5,16z" />
                            </svg>
                        </a>
                </div>
                <p class="font-semibold leading-relaxed text-gray-600 font-work_sans">{{ __('lastCameraWorksDesc') }}
                </p>
            </div>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                <div v-if="Lastworks?.data && Lastworks.data.length != 0" v-for="Lastworks in Lastworks.data"
                    :key="Lastworks.id" class="relative col-span-1 overflow-hidden cursor-pointer">
                    <Suspense>
                        <template #default>
                            <img class="z-0 w-full h-full" :src="Lastworks.img_url ? '/storage/'+Lastworks.img_url : ''"
                                :alt="locale() == 'ar' ? Lastworks.ar_title : Lastworks.en_title">
                        </template>
                        <template #fallback>
                            <div>...</div>
                        </template>
                    </Suspense>
                    <Link :href="route('/')+'/work/'+Lastworks.id"
                        class="absolute top-0 z-10 flex items-center justify-center w-full h-full text-xl font-extrabold text-white opacity-0 bg-gradient-to-t from-gray-600 to-celadon-50 hover:opacity-90">
                    {{ locale() == 'ar' ? Lastworks.ar_title : Lastworks.en_title }}</Link>
                </div>
                <p v-else class="py-10 text-5xl text-center opacity-20 col-span-full">{{ __('nothingYet') }}</p>
            </div>
            <div class="flex flex-row justify-center w-full px-2 py-5 text-center">
                <Link v-if="props.Lastworks?.next_page_url" :href="props.Lastworks.next_page_url"
                    class="px-2 py-1 m-1 text-xl font-extrabold text-white rounded-lg bg-gradient-to-t from-celadon-600 to-celadon-100 hover:opacity-90">
                Next</Link>
                <Link v-if="props.Lastworks?.prev_page_url" :href="props.Lastworks.prev_page_url"
                    class="px-2 py-1 m-1 text-xl font-extrabold text-white rounded-lg bg-gradient-to-t from-celadon-600 to-celadon-100 hover:opacity-90">
                Previous</Link>
            </div>
        </section>
        <!-- vedio2 -->
        <section class="relative z-0 w-full min-h-screen p-5 bg-white md:p-20">
            <div class="relative z-10 bottom-20" id="vedio2"></div>
            <div class="z-0 cloud_bg">
                <svg class="clouds cloud1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" x="0" y="0" width="512" height="512" viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512" xml:space="preserve">
                    <path id="cloud-icon"
                        d="M406.1 227.63c-8.23-103.65-144.71-137.8-200.49-49.05 -36.18-20.46-82.33 3.61-85.22 45.9C80.73 229.34 50 263.12 50 304.1c0 44.32 35.93 80.25 80.25 80.25h251.51c44.32 0 80.25-35.93 80.25-80.25C462 268.28 438.52 237.94 406.1 227.63z" />
                </svg>
                <svg class="clouds cloud2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" x="0" y="0" width="512" height="512" viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512" xml:space="preserve">
                    <path id="cloud-icon"
                        d="M406.1 227.63c-8.23-103.65-144.71-137.8-200.49-49.05 -36.18-20.46-82.33 3.61-85.22 45.9C80.73 229.34 50 263.12 50 304.1c0 44.32 35.93 80.25 80.25 80.25h251.51c44.32 0 80.25-35.93 80.25-80.25C462 268.28 438.52 237.94 406.1 227.63z" />
                </svg>
                <svg class="clouds cloud3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" x="0" y="0" width="512" height="512" viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512" xml:space="preserve">
                    <path id="cloud-icon"
                        d="M406.1 227.63c-8.23-103.65-144.71-137.8-200.49-49.05 -36.18-20.46-82.33 3.61-85.22 45.9C80.73 229.34 50 263.12 50 304.1c0 44.32 35.93 80.25 80.25 80.25h251.51c44.32 0 80.25-35.93 80.25-80.25C462 268.28 438.52 237.94 406.1 227.63z" />
                </svg>
            </div>
            <div class="z-10 grid mb-20 space-y-4 place-items-center">
                <h3 class="text-xl font-bold text-celadon-800 md:text-4xl font-work_sans">{{
                    __('sahabsoftSlide3')
                    }}</h3>
            </div>
            <VedioCard @get-instance="getInstance" class="relative z-10 w-full mt-5 h-80 md:mt-20" :option="option2" />
        </section>
        <!-- Cards Section -->
        <section class="px-5 pt-5 lg:px-24 md:px-16 bg-yellow-50">
            <div class="relative bottom-20" id="ourServices"></div>
            <div class="grid mt-5 space-y-4 place-items-center">
                <h3 class="text-xl font-bold tracking-wide text-teal-800 md:text-5xl font-work_sans">{{
                    __('ourServices')
                    }}</h3>
                <p class="font-semibold leading-relaxed text-gray-600 font-work_sans">
                    {{ __('ourServicesDesc') }}
                </p>
            </div>
            <div class="max-w-6xl px-8 py-16 mx-auto">
                <div v-if="stepCards && Object.keys(stepCards).length != 0" class="relative">
                    <transition name="StepCard" mode="out-in">
                        <StepCard v-if="(cardCount == 1)"
                            :src="stepCards[0].img_url ? '/storage/'+stepCards[0].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[0].ar_title : stepCards[0].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[0].ar_description : stepCards[0].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 2)"
                            :src="stepCards[1].img_url ? '/storage/'+stepCards[1].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[1].ar_title : stepCards[1].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[1].ar_description : stepCards[1].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 3)"
                            :src="stepCards[2].img_url ? '/storage/'+stepCards[2].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[2].ar_title : stepCards[2].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[2].ar_description : stepCards[2].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 4)"
                            :src="stepCards[3].img_url ? '/storage/'+stepCards[3].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[3].ar_title : stepCards[3].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[3].ar_description : stepCards[3].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 5)"
                            :src="stepCards[4].img_url ? '/storage/'+stepCards[4].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[4].ar_title : stepCards[4].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[4].ar_description : stepCards[4].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 6)"
                            :src="stepCards[5].img_url ? '/storage/'+stepCards[5].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[5].ar_title : stepCards[5].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[5].ar_description : stepCards[5].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 7)"
                            :src="stepCards[6].img_url ? '/storage/'+stepCards[6].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[6].ar_title : stepCards[6].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[6].ar_description : stepCards[6].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 8)"
                            :src="stepCards[7].img_url ? '/storage/'+stepCards[7].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[7].ar_title : stepCards[7].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[7].ar_description : stepCards[7].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 9)"
                            :src="stepCards[8].img_url ? '/storage/'+stepCards[8].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[8].ar_title : stepCards[8].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[8].ar_description : stepCards[8].en_description }}
                                </p>
                            </template>
                        </StepCard>
                        <StepCard v-else-if="(cardCount == 10)"
                            :src="stepCards[9].img_url ? '/storage/'+stepCards[9].img_url : ''">
                            <template #title>{{ locale() == 'ar' ? stepCards[9].ar_title : stepCards[9].en_title
                                }}</template>
                            <template #desc>
                                <p>{{ locale() == 'ar' ? stepCards[9].ar_description : stepCards[9].en_description }}
                                </p>
                            </template>
                        </StepCard>
                    </transition>
                    <div class="absolute inset-y-0 left-0 lg:flex lg:items-center" v-show="cardCount != 1">
                        <div @click="StepCardMove(-1);
                        " class="w-12 h-12 p-3 mt-24 -ml-6 bg-white rounded-full shadow-lg cursor-pointer lg:mt-0">
                            <svg class="w-full h-full text-celadon-900" fill="currentColor" viewBox="0 0 24 24">
                                <path
                                    d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z" />
                            </svg>
                        </div>
                    </div>
                    <div class="absolute inset-y-0 right-0 lg:flex lg:items-center" v-show="cardCount != maxCount">
                        <div @click="StepCardMove(1)"
                            class="w-12 h-12 p-3 mt-24 -mr-6 bg-white rounded-full shadow-lg cursor-pointer lg:mt-0">
                            <svg class="w-full h-full text-celadon-900" fill="currentColor" viewBox="0 0 24 24">
                                <path
                                    d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <p v-else class="py-10 text-5xl text-center opacity-20 col-span-full">{{ __('nothingYet') }}</p>
            </div>
        </section>
        <!-- Contact Section -->
        <section class="w-full min-h-fit bg-celadon-500 border-y-4 border-sienna-300">
            <div class="relative bottom-20" id="contact"></div>
            <Contact>
                <template #map>
                    <iframe class="min-h-[20vh] opacity-95 rounded-lg" allowfullscreen width="100%" loading="lazy"
                        height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.5657016112286!2d50.17389811410943!3d26.372905489470064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e5845182ad3d%3A0x409be1ca37d6c12d!2s6341%20King%20Fahd%20Road%2C%20Al%20Hussam%2C%20Dammam%2034232%2C%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1665408813364!5m2!1sen!2s"></iframe>
                </template>
                <template #title>
                    <h1 class="mb-1 text-4xl font-extrabold text-crayola-40">{{ __('letMessage') }}</h1>
                </template>
                <template #desc>
                    <p class="text-xl font-medium text-crayola-20">{{ __('letMessageDesc1') }}, {{ __('letMessageDesc2')
                        }}</p>
                </template>
                <template #form>
                    <form @submit.prevent="send_mail">
                        <div v-if="isSent">
                            <p v-if="isSent == 1" class="p-5 m-5 text-sm font-bold text-gray-200 bg-black/10">
                                {{__('messageSentSuccessfully')}}</p>
                            <p v-else-if="isSent == 2"
                                class="flex flex-col p-5 m-5 text-sm font-bold text-red-300 bg-black/10">
                                <span class="py-1" v-for="error in form.errors">{{ error }}</span>
                            </p>
                        </div>
                        <div class="flex flex-col space-y-4">
                            <div class="flex flex-col space-y-1">
                                <label for="name" class="text-white">{{ __('name') }}</label>
                                <input type="text" name="name" id="name" v-model="form.name"
                                    class="w-full p-2 border border-gray-300 rounded-lg">
                            </div>
                            <div class="flex flex-col space-y-1">
                                <label for="email" class="text-white">{{ __('eMail') }}</label>
                                <input type="email" name="email" id="email" v-model="form.email"
                                    class="w-full p-2 border border-gray-300 rounded-lg">
                            </div>
                            <div class="flex flex-col space-y-1">
                                <label for="message" class="text-white">{{ __('message') }}</label>
                                <textarea name="message" id="message" cols="30" rows="4" v-model="form.message"
                                    class="w-full p-2 border border-gray-300 rounded-lg"></textarea>
                            </div>
                            <div class="flex flex-col space-y-1">
                                <button type="submit" :disabled="form.processing"
                                    :class="'w-full p-2 bg-celadon-100 hover:bg-celadon-200 text-white rounded-lg'+' '+{ 'opacity-25': form.processing }">{{
                                    __('send')
                                    }}</button>
                            </div>
                        </div>
                    </form>
                </template>
            </Contact>
        </section>
        <!-- ambition And Future Section -->
        <section class="px-5 pt-5 md:pt-10 lg:px-24 md:px-16 bg-yellow-50">
            <div class="grid mt-5 space-y-4 place-items-center">
                <h3 class="text-xl font-bold tracking-wide text-teal-800 md:text-5xl font-work_sans">{{
                    __('successPartners')
                    }}</h3>
            </div>
            <div v-if="services?.data && services.data.length != 0" class="w-full">
                <Carousel :settings="settings" :breakpoints="breakpoints" class="w-full h-full" :autoplay="4000"
                    :itemsToShow="3.95" :wrapAround="true">
                    <Slide v-for="service in services.data" :key="service.id"
                        class="inline-flex max-w-6xl p-5 py-16 m-5 mx-auto" disabled="disabled">
                        <div class="w-full proj_item">
                            <span
                                class="flex flex-col justify-center content-center w-full min-h-[20vh] text-center m-2 shadow-lg rounded-lg bg-white">
                                <img :src="'/storage/'+service.img_url" class="w-40 h-40 mx-auto rounded-b-lg"
                                    :alt="locale() == 'ar' ? service.description : service.description" />
                                <span class="w-full py-5 font-extrabold text-celadon-100 hover:text-celadon-300">{{
                                    locale() == 'ar' ? service.ar_title : service.en_title }}</span>
                            </span>
                        </div>
                    </Slide>
                </Carousel>
            </div>
            <p v-else class="py-10 text-5xl text-center opacity-20 col-span-full">{{ __('nothingYet') }}</p>
        </section>

        <!-- Footer -->
    </GuestLayout>
</template>

<style scoped>
.StepCard-enter-active {
    animation: StepCard-in 0.5s;
}

.StepCard-leave-active {
    animation: StepCard-in 0.5s reverse;
}

@keyframes StepCard-in {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    50% {
        transform: scale(1.1);
        opacity: 0.5;
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.carousel__slide>.proj_item {
    transform: scale(1);
    opacity: 0.5;
    transition: 0.5s;
}

.carousel__slide--visible>.proj_item {
    opacity: 1;
    transform: rotateY(0);
}

.carousel__slide--next>.proj_item {
    transform: scale(0.9) translate(-10px);
}

.carousel__slide--prev>.proj_item {
    transform: scale(0.9) translate(10px);
}

.carousel__slide--active>.proj_item {
    transform: scale(1.1);
}
</style>
