<script setup>
//Ar--> -translate-x-2 | En--> translate-x-2
import { Link } from "@inertiajs/inertia-vue3";
import { computed } from "@vue/reactivity";

const props = defineProps({
    LangUrl: {
      default: window.locale === "en" ? "/language/ar" : "/language/en",
    },
    togle: {
      default: true,
    },
    textColor: {
      default: "text-celadon-50",
    },
    bgColor: {
      default: "bg-celadon-400",
    },
});

const classes = computed(() =>{
    return locale === "en"
        ? "rounded-full w-3 h-3 bg-crayola-30 translate-x-2 mx-auto duration-300 ease-in-out"
        : "rounded-full w-3 h-3 bg-crayola-30 translate-x-2 mx-auto duration-300 ease-in-out";
});
</script>

<template>
  <div class="flex items-center m-2 cursor-pointer cm-toggle-wrapper mb-6">
    <span title="ar" :class="'font-mono font-bold'+' '+textColor"> Ar </span>
    <a
      :title="__('language')"
      data-track-arguments="event, Switch, Language"
      :href="LangUrl"
      :class="'rounded-full mx-2 w-8 h-4 p-0.5'+' '+bgColor"
    >
      <div :class="classes"></div>
    </a>
    <span title="en" :class="'font-mono font-bold'+' '+textColor"> En </span>
  </div>
</template>
