<script setup>
import { Link } from "@inertiajs/inertia-vue3";
import { computed } from "@vue/reactivity";
const props = defineProps({
    href: String,
    active: String,
});

const classes = computed(() =>{
    return props.active
        ? "inline-flex p-1 m-1 justify-center items-center content-center text-sm font-medium focus:outline-none text-celadon-50 hover:text-celadon-70 rounded-full duration-500 transition"
        : "inline-flex p-1 m-1 justify-center items-center content-center text-sm font-medium focus:outline-none text-celadon-50 hover:text-celadon-70 rounded-full duration-500 transition";
});
</script>

<template>
  <a target="_blank" title="link"  :href="href" :class="classes">
    <slot />
  </a>
</template>
