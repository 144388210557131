<script setup>
import { reactive } from 'vue';
import { Link } from "@inertiajs/inertia-vue3";
import GuestFooterLinks from "@/Components/Footers/GuestFooterLinks.vue";
import FooterLink from "@/Components/Footers/FooterLink.vue";
import LanguageSwitch from "@/Components/Checkbox/LanguageSwitch.vue";
import SocialLink from "@/Components/Social/SocialLink.vue";

const props = defineProps({
    href: String,
    active: String,
    date: {
        default: new Date().getFullYear(),
    },
    theme:{
        type: String,
        default: "sahabsoft"
    },
});

const themeStyle = reactive({
    footerBg: 'bg-celadon-800',
    footerBorder: 'border-celadon-50',
    footerLink: 'text-crayola-30',
    footerLinkHover: 'hover:text-celadon-10',
    socialBg: 'bg-celadon-700',
    socialFill: 'fill-celadon-20',
    socialLinkFillHover: 'hover:fill-celadon-10',
});
</script>

<template>
    <footer :class="'bg-[url(/images/bg/foot.png)] bg-no-repeat bg-cover bg-top border-t-4 font-bold text-center border-sienna-300 capitalize'+' '+themeStyle.footerBg">
        <section class="grid grid-cols-1 lg:md:grid-cols-3 pt-20 pb-10">
            <GuestFooterLinks class="py-2">
                <ul class="w-full grid grid-cols-1 font-mono">
                    <li>
                        <h2 class="text-sienna-25 clear-both text-2xl cursor-default">
                            {{ __("appName") }}
                        </h2>
                        <p :class="'text-sm mt-4'+' '+themeStyle.footerLink">
                            {{ __("footerDescP1") }}
                        </p>
                    </li>
                    <li class="py-1">
                        <h2 class="text-sienna-25 clear-both text-2xl cursor-default">
                            {{ __("language") }}
                        </h2>
                        <LanguageSwitch class="justify-center" :bgColor="themeStyle.socialBg" :textColor="themeStyle.footerLink"/>
                    </li>
                </ul>
            </GuestFooterLinks>
            <GuestFooterLinks class="py-2">
                <ul class="w-full grid grid-cols-1 font-mono">
                    <li>
                        <h2 class="text-sienna-25 clear-both text-2xl cursor-default">
                            {{ __("usefullLinks") }}
                        </h2>
                    </li>
                    <li>
                        <FooterLink :classes="themeStyle.footerLink+' '+themeStyle.footerLinkHover" :href="route('/')+'/terms-of-service'">
                            {{ __("termsOfService") }}
                        </FooterLink>
                    </li>
                    <li>
                        <FooterLink :classes="themeStyle.footerLink+' '+themeStyle.footerLinkHover" :href="route('/')+'/privacy-policy'">
                            {{ __("privacyPolicy") }}
                        </FooterLink>
                    </li>
                    <li>
                        <FooterLink :classes="themeStyle.footerLink+' '+themeStyle.footerLinkHover" :href="route('/')+'/terms-of-service'">
                            {{ __("cookies") }}
                        </FooterLink>
                    </li>
                    <li>
                        <h2 class="text-sienna-25 clear-both text-2xl cursor-default">
                            {{ __("contact") }}
                        </h2>
                    </li>
                    <li>
                        <a :href="'mailto:' + __('contactMail1')+','+ __('contactMail2')" :class="'inline-flex p-1 focus:outline-none transition'+' '+themeStyle.footerLink+' '+themeStyle.footerLinkHover">
                            {{ __("eMail") }}:
                            <br> {{ __("contactMail1") }}
                        </a>
                    </li>
                </ul>
            </GuestFooterLinks>
            <GuestFooterLinks class="py-2">
                <ul class="w-full grid grid-cols-1 font-mono">
                    <li>
                        <h2 class="text-sienna-25 clear-both text-2xl cursor-default">
                            {{ __("address") }}
                        </h2>
                    </li>
                    <li>
                        <FooterLink :classes="themeStyle.footerLink+' py-0 my-0 '+themeStyle.footerLinkHover" :href="route('/')">
                            <br> {{ __("contactAddress1") }}
                        </FooterLink>
                        <FooterLink :classes="themeStyle.footerLink+' py-0 my-0 '+themeStyle.footerLinkHover" :href="route('/')">
                            <br> {{ __("contactAddress2") }}
                        </FooterLink>
                        <FooterLink :classes="themeStyle.footerLink+' py-0 my-0 '+themeStyle.footerLinkHover" :href="route('/')">
                            <br> {{ __("contactAddress4") }}
                        </FooterLink>
                        <FooterLink :classes="themeStyle.footerLink+' py-0 my-0 '+themeStyle.footerLinkHover" :href="route('/')">
                            <br> {{ __("contactAddress5") }}
                        </FooterLink>
                    </li>
                </ul>
            </GuestFooterLinks>
        </section>
        <div :class="'border-t-2 border-opacity-40'+' '+themeStyle.footerBorder">
            <div class="relative">
                <ul class="w-full grid grid-cols-1 font-mono absolute -top-5">
                <li class="flex flex-row justify-center">
                    <SocialLink href="https://www.facebook.com/sahabsoft/" :class="'w-8 h-8'+' '+themeStyle.socialBg">
                        <svg :class="'w-6 h-6  transition duration-500 hover:scale-150'+' '+themeStyle.socialFill+' '+themeStyle.socialLinkFillHover"
                            role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title>Facebook</title>
                            <path
                                d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                        </svg>
                    </SocialLink>
                    <SocialLink href="https://www.instagram.com/sahabsoft/" :class="'w-8 h-8'+' '+themeStyle.socialBg">
                        <svg :class="'w-6 h-6  transition duration-500 hover:scale-150'+' '+themeStyle.socialFill+' '+themeStyle.socialLinkFillHover"
                            role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title>Instagram</title>
                            <path
                                d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                        </svg>
                    </SocialLink>
                    <SocialLink href="https://twitter.com/sahabsoft1" :class="'w-8 h-8'+' '+themeStyle.socialBg">
                        <svg viewBox="0 0 20 20" aria-hidden="true" :class="'w-6 h-6 transition duration-500 hover:scale-150'+' '+themeStyle.socialFill+' '+themeStyle.socialLinkFillHover">
                            <title>Twitter</title>
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 20 3.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 .8 7.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.095 4.095 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 0 16.407a11.615 11.615 0 0 0 6.29 1.84"></path>
                        </svg>
                    </SocialLink>
                    <SocialLink href="https://t.me/sahabsoft" :class="'w-8 h-8'+' '+themeStyle.socialBg">
                        <svg :class="'w-6 h-6 transition duration-500 hover:scale-150'+' '+themeStyle.socialFill+' '+themeStyle.socialLinkFillHover"
                            role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title>Telegram</title>
                            <path
                                d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" />
                        </svg>
                    </SocialLink>
                    <SocialLink href="https://wa.me/966548490290" :class="'w-8 h-8'+' '+themeStyle.socialBg">
                        <svg :class="'w-6 h-6 transition duration-500 hover:scale-150'+' '+themeStyle.socialFill+' '+themeStyle.socialLinkFillHover"
                            role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title>WhatsApp</title>
                            <path
                                d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                        </svg>
                    </SocialLink>
                </li>
            </ul>
            </div>

            <p :class="'flex justify-center content-center items-center py-5 font-sans'+' '+themeStyle.footerLink">
                {{ __("footerCopyRight") }}
                <a target="_blank" rel="noopener" :class="'inline-flex text-center p-1 items-center text-sm transition focus:outline-none'+' '+themeStyle.footerLink+' '+themeStyle.footerLinkHover" href="https://sahabsoft.net/">
                    {{ __("sahabsoft") }}
                </a>
                2022 - {{ date }}
            </p>
        </div>
    </footer>
</template>
