<script setup>
import { Link } from "@inertiajs/inertia-vue3";
import { ref } from 'vue';
import JetDropdown from '@/Jetstream/Dropdown.vue';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue';

const props = defineProps({
    theme: {
        type: String,
        default: "sahabsoft"
    },
    linksClass: {
        type: String,
        default: ""
    },
});

const showingNavigationDropdown = ref(false);

const logout = () => {
    Inertia.post(route('logout'));
};
</script>

<template>
    <nav>
        <!-- Primary Navigation Menu -->
        <div class="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <!-- Logo -->
                    <div class="flex items-center shrink-0">
                        <Link :href="route('/')">
                            <Suspense>
                                <template #default>
                                    <img
                                        class="block h-16 drop-shadow"
                                        src="/images/logo/sahabsoft.png"
                                        alt="logo"
                                    />
                                </template>
                                <template #fallback>
                                    <div>...</div>
                                </template>
                            </Suspense>
                        </Link>
                    </div>
                </div>

                <div class="hidden sm:flex sm:items-center sm:ml-6">
                    <!-- Navigation Links -->
                    <div class="hidden mx-5 sm:-my-px sm:mx-5 sm:flex">
                        <Link :class="'inline-flex items-center py-1 border-b-2 border-transparent font-medium leading-5 focus:outline-none transition mx-2 px-2 '+linksClass" v-smooth-scroll :href="route('/')">
                            {{ __("home") }}
                        </Link>
                        <Link :class="'inline-flex items-center py-1 border-b-2 border-transparent font-medium leading-5 focus:outline-none transition mx-2 px-2 '+linksClass" v-smooth-scroll href="#about">
                            {{ __("aboutUs") }}
                        </Link>
                        <Link :class="'inline-flex items-center py-1 border-b-2 border-transparent font-medium leading-5 focus:outline-none transition mx-2 px-2 '+linksClass" v-smooth-scroll href="#lastWorks">
                            {{ __("lastWorks") }}
                        </Link>
                        <Link :class="'inline-flex items-center py-1 border-b-2 border-transparent font-medium leading-5 focus:outline-none transition mx-2 px-2 '+linksClass" v-smooth-scroll href="#lastCameraWorks">
                            {{ __("CameraWorks") }}
                        </Link>
                        <Link :class="'inline-flex items-center py-1 border-b-2 border-transparent font-medium leading-5 focus:outline-none transition mx-2 px-2 '+linksClass" v-smooth-scroll href="#ourServices">
                            {{ __("ourServices") }}
                        </Link>
                        <Link :class="'inline-flex items-center py-1 border-b-2 border-transparent font-medium leading-5 focus:outline-none transition mx-2 px-2 '+linksClass" v-smooth-scroll href="#contact">
                            {{ __("contact") }}
                        </Link>
                    </div>
                </div>

                <!-- Hamburger -->
                <div class="flex items-center -mr-2 sm:hidden">
                    <button
                        class="inline-flex items-center justify-center p-2 text-gray-300 transition rounded-md filter hover:backdrop-blur-md focus:outline-none duration-400"
                        @click="showingNavigationDropdown = !showingNavigationDropdown">
                        <svg class="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path
                                :class="{ 'hidden': showingNavigationDropdown, 'inline-flex': !showingNavigationDropdown }"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16" />
                            <path
                                :class="{ 'hidden': !showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div :class="{ 'block': showingNavigationDropdown, 'hidden': !showingNavigationDropdown }" class="transition sm:hidden backdrop-blur-md duration-400 rounded-b-3xl">
            <div class="pt-2 pb-3 space-y-1 text-center">
                <Link class="block py-2 pl-3 pr-4 text-base font-medium transition text-celadon-50 hover:bg-gray-50/80 hover:text-celadon-100 focus:outline-none" v-smooth-scroll :href="route('/')">
                    {{ __("home") }}
                </Link>
                <Link class="block py-2 pl-3 pr-4 text-base font-medium transition text-celadon-50 hover:bg-gray-50/80 hover:text-celadon-100 focus:outline-none" v-smooth-scroll href="#about">
                    {{ __("aboutUs") }}
                </Link>
                <Link class="block py-2 pl-3 pr-4 text-base font-medium transition text-celadon-50 hover:bg-gray-50/80 hover:text-celadon-100 focus:outline-none" v-smooth-scroll href="#lastWorks">
                    {{ __("lastWorks") }}
                </Link>
                <Link class="block py-2 pl-3 pr-4 text-base font-medium transition text-celadon-50 hover:bg-gray-50/80 hover:text-celadon-100 focus:outline-none" v-smooth-scroll href="#lastCameraWorks">
                    {{ __("CameraWorks") }}
                </Link>
                <Link class="block py-2 pl-3 pr-4 text-base font-medium transition text-celadon-50 hover:bg-gray-50/80 hover:text-celadon-100 focus:outline-none" v-smooth-scroll href="#ourServices">
                    {{ __("ourServices") }}
                </Link>
                <Link class="block py-2 pl-3 pr-4 text-base font-medium transition text-celadon-50 hover:bg-gray-50/80 hover:text-celadon-100 focus:outline-none" v-smooth-scroll href="#contact">
                    {{ __("contact") }}
                </Link>
            </div>
        </div>
    </nav>
</template>
